import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Typography, Tab, Tabs } from '@mui/material'
import ParkingPaymentHistoryTable from '../../components/Parking/ParkingPaymentHistoryTable'
import { useParams } from 'react-router-dom'
export enum ParkingProductType {
  subscription = 'subscription',
  oneTimeTicket = 'oneTimeTicket',
}
const ParkingPaymentHistory = () => {
  const { t } = useTranslation(['payment_history'])

  const tabs = [
    ParkingProductType.subscription,
    ParkingProductType.oneTimeTicket,
  ]
  const [value, setValue] = useState(ParkingProductType.subscription)
  const { param } = useParams()

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue as ParkingProductType)
  }

  useEffect(() => {
    if (!!param) {
      setValue(param.split(':')[2] as ParkingProductType)
    }
  }, [])

  return (
    <>
      <TypographyStyled variant="h6">
        {t('parking_payment_history')}
      </TypographyStyled>
      <TabsStyled
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
      >
        {tabs.map((tab: string, i) => (
          <Tab
            label={t(`${tab}`)}
            value={tab}
            key={i}
            data-e2e={`tab-${tab}`}
          />
        ))}
      </TabsStyled>
      <ParkingPaymentHistoryTable productType={value} />
    </>
  )
}

export default ParkingPaymentHistory

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
const TabsStyled = styled(Tabs)`
  margin-bottom: 20px;
`
