import gql from 'graphql-tag'
import { IssueStatus } from './queryIssue'
import { UserAnonymizeStatus } from '../users/queryUserWithPagination'

export interface IDeleteAccountRequestsReportData {
  queryIssue: {
    createdAt: string
    description: string
    status: IssueStatus
    comment: string
    resolvedBy: {
      login: string
    }
    user: {
      disabled: boolean
      anonymizeStatus: UserAnonymizeStatus
      tenant: {
        name: string
      }
    }
  }[]
}

export const DELETE_ACCOUNT_REQUESTS_REPORT = gql`
  query DeleteAccountRequestsReport {
    queryIssue(filter: { type: { eq: account_delete_request } }) {
      createdAt: created_at
      description
      status
      comment
      resolvedBy: resolved_by {
        login
      }
      user {
        disabled
        anonymizeStatus: anonymize_status
        tenant {
          name
        }
      }
    }
  }
`
