import { useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
  onlyDateIso2ExcelFormat,
  timeIso2ExcelFormat,
} from '../../utils/formatDate'
import { getPaymentStatusColor } from '../../utils/getStatusColor'
import { PaymentStatus } from '../../graphql/shared/sharedTypes'
import { formatPrice, formatToExcelPrice } from '../../utils/formatPrice'
import PaymentDetails from '../../components/Parking/PaymentDetails'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import { useParams } from 'react-router-dom'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'
import { ParkingProductType } from '../../screens/Parking/ParkingPaymentHistory'
import { useFetchFilteredParkingTicketPayments } from '../../hooks/useFetchFilteredParkingTicketPayments'
import ParkingPaymentFilters from './ParkingPaymentFilters'
import styled from 'styled-components'
import DownloadCSVLink from '../../components/Shared/DownloadCSVLink'
import { useLazyQuery } from '@apollo/client'
import {
  ParkingProduct,
  IQueryParkingTicketPaymentData,
  IQueryParkingTicketPaymentVars,
  QUERY_PARKING_SUBSCRIPTION_PAYMENT_CSV,
} from '../../graphql/parking/queryParkingSubscriptionPayment'
import { QUERY_ONE_TIME_PARKING_TICKET_PAYMENT_CSV } from '../../graphql/parking/queryOneTimeParkingTicketPayment'

export interface ParkingTicketPaymentRow {
  ticketId: string
  id: string
  paymentId?: string
  paymentDate?: string
  paymentTime?: string
  paymentDay?: string
  createdAt?: string
  createdAtCSV: string
  validFrom: string
  validTo: string
  validFromCSV: string
  validToCSV?: string
  productName: string
  parkingName?: string
  fvCSV: string
  login: string
  fullName: string
  nip?: string
  buyerName?: string
  zipcode?: string
  street?: string
  city?: string
  country?: string
  carPlate: string
  tenantName?: string
  status?: PaymentStatus
  price?: string
  priceCSV?: string
  sandbox?: string
  duration?: string
}

export enum ParkingTicketPaymentFilter {
  carPlate = 'carPlate',
  paymentId = 'paymentId',
  paymentDate = 'paymentDate',
  paymentStatus = 'paymentStatus',
  lastName = 'lastName',
  login = 'login',
}

const ParkingPaymentHistoryTable = ({
  productType,
}: {
  productType: ParkingProductType
}) => {
  const { t, i18n } = useTranslation(['payment_history'])
  const lang = i18n.language
  const [openedDetails, setOpenedDetails] = useState<ParkingTicketPaymentRow>()
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [filterKey, setFilterKey] = useState<ParkingTicketPaymentFilter | ''>(
    '',
  )
  const [filterValue, setFilterValue] = useState('')
  const [error, setError] = useState('')
  const [CSVData, setCSVData] = useState<ParkingTicketPaymentRow[]>([])
  const { param } = useParams()
  const subscription = productType === ParkingProductType.subscription
  const {
    parkingTicketsPayments,
    loading,
    itemsCount,
    getFilteredParkingTicketPayments,
  } = useFetchFilteredParkingTicketPayments(
    productType,
    pageSize,
    currentPage,
    filterKey,
    filterValue,
    setError,
    param,
  )

  const formatDuration = (v: string) => v.replace('h', ':').split('m')[0]

  const mapper = (data: ParkingProduct[]) => {
    const mapped = data?.map(
      ({
        id,
        carPlate,
        carPlate2,
        user: { login, firstName, lastName, tenant },
        parkingName,
        invoiceNeeded,
        buyer,
        payment,
        validFrom,
        validTo,
        parkingPass,
        duration,
      }) => ({
        ticketId: id,
        id: payment?.paymentId,
        paymentId: payment?.paymentId,
        paymentDate:
          payment?.createdAt && onlyDateIso2ExcelFormat(payment.createdAt),
        paymentTime:
          payment?.createdAt && timeIso2ExcelFormat(payment.createdAt),
        paymentDay:
          payment?.createdAt &&
          dateIso2localeString(payment.createdAt, lang, {
            weekday: 'long',
          }),
        validFromDay: dateIso2localeString(validFrom, lang, {
          weekday: 'long',
        }),
        createdAt: payment?.createdAt,
        createdAtCSV:
          payment?.createdAt && dateIso2ExcelFormat(payment.createdAt),
        validFrom,
        validFromCSV: dateIso2ExcelFormat(validFrom),
        validTo,
        validToCSV: dateIso2ExcelFormat(validTo),
        productName: parkingPass ? parkingPass.name : t('oneTimeTicket'),
        parkingName: parkingName
          ? parkingName
          : parkingPass?.parking.parkingName,
        fvCSV: invoiceNeeded ? t('bool:yes') : t('bool:no'),
        login,
        fullName: `${firstName} ${lastName}`,
        nip: buyer?.nip,
        buyerName: buyer?.buyerName,
        zipcode: buyer?.zipcode,
        street: buyer?.street,
        city: buyer?.city,
        country: buyer?.country,
        carPlate: carPlate2 ? `${carPlate}, ${carPlate2}` : carPlate,
        tenantName: tenant?.tenantName,
        status: payment?.status,
        price: payment?.amount ? formatPrice(payment.amount) : '0',
        priceCSV: payment?.amount ? formatToExcelPrice(payment.amount) : '0',
        sandbox: payment?.sandbox ? 'sandbox' : undefined,
        duration: duration && formatDuration(duration),
      }),
    )
    return mapped
  }

  const [queryParkingTicketPayments] = useLazyQuery<
    IQueryParkingTicketPaymentData,
    IQueryParkingTicketPaymentVars
  >(
    subscription
      ? QUERY_PARKING_SUBSCRIPTION_PAYMENT_CSV
      : QUERY_ONE_TIME_PARKING_TICKET_PAYMENT_CSV,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data: IQueryParkingTicketPaymentData) => {
        setCSVData(mapper(data.queryParkingTicket))
      },
    },
  )

  const invisibleColumns = {
    paymentDate: false,
    paymentTime: false,
    paymentDay: false,
    createdAtCSV: false,
    validFromDay: false,
    validFromCSV: false,
    validToCSV: false,
    fvCSV: false,
    nip: false,
    buyerName: false,
    zipcode: false,
    street: false,
    city: false,
    country: false,
    tenantName: false,
    priceCSV: false,
    duration: false,
  }

  const csvHeaders = [
    {
      label: t('payment_id'),
      key: 'id',
    },
    {
      label: t('payment_date'),
      key: 'createdAtCSV',
    },
    {
      label: 'Data płatności',
      key: 'paymentDate',
    },
    {
      label: 'Godzina płatności',
      key: 'paymentTime',
    },
    {
      label: 'Dzień płatności',
      key: 'paymentDay',
    },
    {
      label: t('ticket_start'),
      key: 'validFromCSV',
    },
    {
      label: 'Dzień rozpoczęcia biletu',
      key: 'validFromDay',
    },
    {
      label: t('ticket_stop'),
      key: 'validToCSV',
    },
    {
      label: t('product_name'),
      key: 'productName',
    },
    {
      label: 'Parking',
      key: 'parkingName',
    },
    {
      label: 'FV',
      key: 'fvCSV',
    },
    {
      label: 'NIP',
      key: 'nip',
    },
    {
      label: t('columns:full_name'),
      key: 'fullName',
    },
    {
      label: t('zipcode'),
      key: 'zipcode',
    },
    {
      label: t('street'),
      key: 'street',
    },
    {
      label: t('city'),
      key: 'city',
    },
    {
      label: t('country'),
      key: 'country',
    },
    {
      label: t('car_plate_1'),
      key: 'carPlate',
    },
    {
      label: t('tenant_name'),
      key: 'tenantName',
    },
    {
      label: `${t('payment_value_gross')} (PLN)`,
      key: 'priceCSV',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: 'Czas parkowania',
      key: 'duration',
    },
  ]

  const columns: ColumnDef<ParkingTicketPaymentRow, any>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: t('payment_id'),
      },
      {
        accessorKey: 'createdAt',
        header: t('payment_date'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'paymentDate',
        header: t('payment_date'),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'paymentTime',
        header: 'Godzina płatności',
      },
      {
        accessorKey: 'paymentDay',
        header: 'Dzień tygodnia płatności',
      },
      {
        accessorKey: 'createdAtCSV',
        header: t('payment_date_csv'),
      },
      {
        accessorKey: 'validFrom',
        header: t('ticket_start'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validTo',
        header: t('ticket_stop'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validFromDay',
        header: 'Dzień tygodnia',
      },
      {
        accessorKey: 'validFromCSV',
        header: t('ticket_start'),
      },
      {
        accessorKey: 'validToCSV',
        header: t('ticket_stop'),
      },
      {
        accessorKey: 'productName',
        header: t('product_name'),
      },
      {
        accessorKey: 'status',
        header: t('payment_status'),
        cell: (value) => t(`payment_status:${value.getValue()}`),
      },
      {
        accessorKey: 'parkingName',
        header: t('parking_name'),
      },
      {
        accessorKey: 'fvCSV',
        header: t('fv'),
      },
      {
        accessorKey: 'fullName',
        header: t('columns:full_name'),
      },
      {
        accessorKey: 'login',
        header: t('columns:login'),
      },
      {
        accessorKey: 'nip',
        header: t('nip'),
      },
      {
        accessorKey: 'buyerName',
        header: t('name'),
      },
      {
        accessorKey: 'zipcode',
        header: t('zipcode'),
      },
      {
        accessorKey: 'street',
        header: t('street'),
      },
      {
        accessorKey: 'city',
        header: t('city'),
      },
      {
        accessorKey: 'country',
        header: t('country'),
      },
      {
        accessorKey: 'carPlate',
        header: t('car_plate_1'),
      },
      {
        accessorKey: 'tenantName',
        header: t('tenant_name'),
      },
      {
        accessorKey: 'price',
        header: t('payment_value_gross'),
      },
      {
        accessorKey: 'priceCSV',
        header: t('payment_value_gross'),
      },
      {
        accessorKey: 'sandbox',
        header: 'Sandbox',
        meta: {
          filterVariant: 'select',
          options: [
            { label: 'Tak', value: true },
            { label: 'Nie', value: false },
          ],
        },
      },
      {
        accessorKey: 'duration',
        header: 'Czas parkowania',
      },
    ],
    [t, lang],
  )

  const handleSelectRows = useCallback(
    (selectedRowsData: ParkingTicketPaymentRow[]) => {
      setCSVData(selectedRowsData)
    },
    [],
  )

  const tableData: ParkingTicketPaymentRow[] = useMemo(
    () => mapper(parkingTicketsPayments) || [],
    [parkingTicketsPayments, productType],
  )

  const setFilterFromRoute = async () => {
    await setFilterKey(param?.split(':')[0] as ParkingTicketPaymentFilter)
    await setFilterValue(param?.split(':')[1] || '')
  }

  useEffect(() => {
    if (param) {
      setFilterFromRoute()
    }
  }, [param])

  return (
    <>
      <Row>
        <DownloadCSVLink
          data={CSVData}
          csvHeaders={csvHeaders}
          fileName="parking-payments.csv"
          filterCsvValue={t('bool:yes')}
          filterCsvKey="disabled"
          getData={queryParkingTicketPayments}
        />
      </Row>
      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        manualPagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        count={itemsCount}
        pageIndex={currentPage}
        setCurrentPage={setCurrentPage}
        manualSorting
        globalSearch={false}
        manualFiltering
        enableColumnFilters={false}
        loading={loading}
        onSelectionChange={handleSelectRows}
        onRowClick={(rowData: ParkingTicketPaymentRow) => {
          rowData && setOpenedDetails(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'status' && !!row.original.status
            ? {
                style: {
                  color: getPaymentStatusColor(row.original.status),
                  fontWeight: 600,
                },
              }
            : {}
        }
      >
        <ParkingPaymentFilters
          productType={productType}
          filterKey={filterKey}
          setFilterKey={setFilterKey}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          setCurrentPage={setCurrentPage}
          params={param}
        />
      </Table>
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        {openedDetails && (
          <PaymentDetails
            closeDetails={() => setOpenedDetails(undefined)}
            data={openedDetails}
            refetch={getFilteredParkingTicketPayments}
          />
        )}
      </BaseDrawer>
    </>
  )
}

export default ParkingPaymentHistoryTable
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
