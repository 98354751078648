import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { Grid } from '@mui/material'
import {
  QUERY_TENANT,
  IQueryTenantData,
} from '../../graphql/shared/queryTenant'
import {
  CLEAR_USER_TENANT,
  CHANGE_USER_TENANT,
  IUpdateUserData,
  IChangeUserTenantVars,
} from '../../graphql/users/userTenantActions'
import BaseModal from '../Shared/BaseModal'
import BaseSelect, { OptionType } from '../Shared/BaseSelect'
import AddTenantForm from '../Tenant/AddTenantForm'
import { ActionMeta } from 'react-select'
import { toast } from 'react-toastify'
import { Title } from '../Styles/TextCustom'
import { IUser } from '../../graphql/users/queryUserWithPagination'
import styled from 'styled-components'
import { themeColors } from '../../const/colors'
import { BaseTextButton } from '../Shared/BaseButton'

const UserTenant: FC<{
  userData: IUser
  refetch: () => void
}> = ({ userData, refetch }) => {
  const { t } = useTranslation(['form', 'btn', 'tenant', 'user_details'])
  const [newTenantModal, setNewTenantModal] = useState(false)
  const [selectedTenant, setSelectedTenant] = useState<OptionType | null>(null)
  const { tenant, login } = userData
  const successNotify = (message: string) => toast.dark(message)
  const {
    data: tenants,
    loading: loadingTenants,
    refetch: refetchTenants,
  } = useQuery<IQueryTenantData>(QUERY_TENANT, {
    fetchPolicy: 'cache-first',
  })

  const [changeUserTenant, { loading: tenantChangeLoading }] = useMutation<
    IUpdateUserData,
    IChangeUserTenantVars
  >(CHANGE_USER_TENANT, {
    onCompleted: ({ updateUser: { user } }: IUpdateUserData) => {
      successNotify(`${t('user_details:tenant_change_success')}`)
      const tenant = user[0].tenant
      tenant && setSelectedTenant({ value: tenant.id, label: tenant.name })
      refetch()
    },
  })

  const [clearUserTenant, { loading: tenantClearLoading }] = useMutation<
    IUpdateUserData,
    IChangeUserTenantVars
  >(CLEAR_USER_TENANT, {
    onCompleted: ({ updateUser: { user } }: IUpdateUserData) => {
      successNotify(`${t('user_details:tenant_deleted_success')}`)
      const tenant = user[0].tenant
      !tenant && setSelectedTenant(tenant)
      refetch()
    },
  })

  useEffect(() => {
    setSelectedTenant(
      tenant?.id && tenant?.name
        ? { value: tenant.id, label: tenant.name }
        : null,
    )
  }, [tenant])

  const handleSelectChange = (
    value: OptionType | null,
    actionMeta: ActionMeta<OptionType>,
  ) => {
    if (actionMeta.action === 'select-option' && value) {
      changeUserTenant({ variables: { login, tenantId: value.value } })
    } else if (actionMeta.action === 'clear' && selectedTenant) {
      clearUserTenant({
        variables: { login, tenantId: selectedTenant.value },
      })
    }
  }

  const isSelectLoading =
    tenantChangeLoading || tenantClearLoading || loadingTenants

  return (
    <BlueContainer item xs={6}>
      <Title>{t('user_details:company')}</Title>

      <Grid data-e2e="usersList-userCompanyInfo-select">
        <BaseSelect
          placeholder={t('select_tenant')}
          name="tenant"
          options={tenants?.queryTenant.map(({ name, id }) => ({
            value: id,
            label: name,
          }))}
          value={selectedTenant}
          onChange={handleSelectChange}
          noOptionsMessage={() => t('no_tenants')}
          isLoading={isSelectLoading}
          isDisabled={isSelectLoading}
        />
      </Grid>
      <ButtonWrapper>
        <BaseTextButton onClick={() => setNewTenantModal(true)}>
          {`+ ${t('btn:add_tenant')}`}
        </BaseTextButton>
      </ButtonWrapper>

      <BaseModal
        title={t('tenant:add_new_tenant')}
        open={newTenantModal}
        actions={false}
      >
        <AddTenantForm
          handleClose={() => setNewTenantModal(false)}
          handleUpdateTenants={refetchTenants}
        />
      </BaseModal>
    </BlueContainer>
  )
}

export default UserTenant

const BlueContainer = styled(Grid)`
  background-color: ${themeColors.lightBlue};
  border-radius: 20px;
  padding: 20px;
  margin-top: 16px;
  margin-right: 8px;
  flex: 1;
`
const ButtonWrapper = styled.div`
  padding: 8px 0;
`
