import gql from 'graphql-tag'

export const ONE_TIME_PARKING_TICKET_FRAGMENT = gql`
  fragment OneTimeParkingTicketParts on OneTimeParkingTicket {
    id
    buyer {
      city
      buyerName: name
      country
      nip
      street
      zipcode
    }
    duration
    invoiceNeeded: invoice_needed
    parkingName: parking_name
    price
    validFrom: valid_from
    validTo: valid_to
    createdAt: created_at
  }
`

export const QUERY_ONE_TIME_PARKING_TICKET_PAYMENT = gql`
  ${ONE_TIME_PARKING_TICKET_FRAGMENT}
  query queryOneTimeParkingTicket(
    $first: Int
    $offset: Int
    $filter: OneTimeParkingTicketFilter
  ) {
    queryParkingTicket: queryOneTimeParkingTicket(
      first: $first
      offset: $offset
      order: { desc: created_at }
      filter: $filter
    ) {
      ...OneTimeParkingTicketParts
      payment {
        paymentId: session_id
        createdAt: created_at
        status
        sandbox
        amount
      }
      carPlate: car_plate
      user {
        login
        firstName: first_name
        lastName: last_name
        tenant {
          tenantName: name
        }
      }
    }
    aggregateParkingTicket: aggregateOneTimeParkingTicket(filter: $filter) {
      count
    }
  }
`

export const QUERY_ONE_TIME_PARKING_TICKET_PAYMENT_WITH_PAYMENT_FILTER = gql`
  ${ONE_TIME_PARKING_TICKET_FRAGMENT}
  query queryOneTimeParkingTicket(
    $first: Int
    $offset: Int
    $paymentFilter: PaymentFilter
  ) {
    queryParkingTicket: queryOneTimeParkingTicket(
      first: $first
      offset: $offset
    )
      # order: { desc: created_at }
      @cascade(fields: ["payment"]) {
      ...OneTimeParkingTicketParts
      payment(filter: $paymentFilter) {
        paymentId: session_id
        createdAt: created_at
        status
        sandbox
        amount
      }
      carPlate: car_plate
      user {
        login
        firstName: first_name
        lastName: last_name
        tenant {
          tenantName: name
        }
      }
    }
    aggregateParkingTicket: aggregateOneTimeParkingTicket {
      count
    }
  }
`

export const QUERY_ONE_TIME_PARKING_TICKET_PAYMENT_WITH_USER_FILTER = gql`
  ${ONE_TIME_PARKING_TICKET_FRAGMENT}
  query queryOneTimeParkingTicket(
    $first: Int
    $offset: Int
    $userFilter: UserFilter
  ) {
    queryParkingTicket: queryOneTimeParkingTicket(
      first: $first
      offset: $offset
    )
      # order: { desc: created_at }
      @cascade(fields: ["user"]) {
      ...OneTimeParkingTicketParts
      payment {
        paymentId: session_id
        createdAt: created_at
        status
        sandbox
        amount
      }
      carPlate: car_plate
      user(filter: $userFilter) {
        login
        firstName: first_name
        lastName: last_name
        tenant {
          tenantName: name
        }
      }
    }
    aggregateParkingTicket: aggregateOneTimeParkingTicket {
      count
    }
  }
`
export const QUERY_ONE_TIME_PARKING_TICKET_PAYMENT_CSV = gql`
  ${ONE_TIME_PARKING_TICKET_FRAGMENT}
  query queryOneTimeParkingTicket {
    queryParkingTicket: queryOneTimeParkingTicket {
      ...OneTimeParkingTicketParts
      payment {
        paymentId: session_id
        createdAt: created_at
        status
        sandbox
        amount
      }
      carPlate: car_plate
      user {
        login
        firstName: first_name
        lastName: last_name
        tenant {
          tenantName: name
        }
      }
    }
  }
`
