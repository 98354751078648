import { useMemo, useState, useLayoutEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import { Typography, Grid } from '@mui/material'
import styled from 'styled-components'
import { ParkingSubscriptionStatus } from '../../graphql/parking/queryParkingSubscriptions'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import SubscriptionDetails from '../../components/Parking/SubscriptionDetails'
import { getSubscriptionStatusColor } from '../../utils/getStatusColor'
import { formatToExcelPrice } from '../../utils/formatPrice'
import { useParams, useNavigate } from 'react-router-dom'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'
import { useFetchFilteredParkingSubscriptions } from '../../hooks/useFetchFilteredParkingSubscriptions'
import ParkingSubscriptionFilters from '../../components/Parking/ParkingSubscriptionFilters'
import { useLazyQuery } from '@apollo/client'
import DownloadCSVLink from '../../components/Shared/DownloadCSVLink'
import {
  ISubscription,
  IQuerySubscriptionCSVVars,
  IQuerySubscriptionData,
  QUERY_PARKING_SUBSCRIPTIONS_CSV,
} from '../../graphql/parking/queryParkingSubscriptions'
import { HeaderContainer } from '../../components/Styles/CustomElementsStyled'
import { date2isoString } from '../../utils/formatDate'

export interface ParkingSubscriptionRow {
  id: string
  status: ParkingSubscriptionStatus
  validFrom: string
  validTo: string
  validFromCSV: string
  validToCSV: string
  createdAt: string
  createdAtCSV: string
  carPlates: string
  productName: string
  login: string
  email: string
  tenant: string
  priceCSV?: string
}

export enum ParkingSubscriptionFilter {
  productName = 'productName',
  carPlate = 'carPlate',
  status = 'status',
  login = 'login',
  email = 'email',
}

const SoldSubscriptionsList = () => {
  const { t, i18n } = useTranslation(['sold_parking_items'])
  const now = new Date(new Date().setHours(0, 0, 0, 0))
  const [minValidTo, setMinValidTo] = useState<Date | null>(now)
  const [openedDetails, setOpenedDetails] = useState<ParkingSubscriptionRow>()
  const [error, setError] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [filterKey, setFilterKey] = useState<ParkingSubscriptionFilter | ''>('')
  const [filterValue, setFilterValue] = useState('')
  const [CSVData, setCSVData] = useState<ParkingSubscriptionRow[]>([])
  const lang = i18n.language
  const navigate = useNavigate()
  const params = useParams() as { subscriptionId: string }
  const {
    subscriptions,
    loading,
    itemsCount,
    getFilteredParkingSubscriptions,
  } = useFetchFilteredParkingSubscriptions(
    minValidTo,
    pageSize,
    currentPage,
    filterKey,
    filterValue,
    setError,
  )

  const mapper = (data: ISubscription[]) => {
    const mapped = data.map(
      ({
        id,
        status,
        validFrom,
        validTo,
        createdAt,
        parkingPass: { name, nameEn },
        user: { login, email, tenant },
        activeParkingTicket,
        pendingParkingTicket,
        tickets,
      }) => {
        const ticket = activeParkingTicket || pendingParkingTicket || tickets[0]
        return {
          id,
          validFrom,
          validTo,
          validFromCSV: dateIso2ExcelFormat(validFrom),
          validToCSV: dateIso2ExcelFormat(validTo),
          carPlates: `${ticket?.carPlate}${
            ticket?.carPlate2 ? `, ${ticket?.carPlate2}` : ''
          }`,
          productName: lang === 'pl' ? name : nameEn,
          login,
          email: email?.email,
          status,
          createdAt,
          createdAtCSV: dateIso2ExcelFormat(createdAt),
          tenant: tenant?.name,
          priceCSV: formatToExcelPrice(ticket?.payment?.amount) || '',
        }
      },
    )
    return mapped
  }

  const [queryParkingSubscriptions] = useLazyQuery<
    IQuerySubscriptionData,
    IQuerySubscriptionCSVVars
  >(QUERY_PARKING_SUBSCRIPTIONS_CSV, {
    fetchPolicy: 'no-cache',
    variables: { minValidTo: `${minValidTo && date2isoString(minValidTo)}` },
    onCompleted: (data: IQuerySubscriptionData) =>
      setCSVData(mapper(data.subscriptions)),
  })

  const handleCloseDrawer = () => {
    setOpenedDetails(undefined)
    navigate('/admin/parking/sold-subscriptions', {
      state: {},
      replace: true,
    })
  }

  const invisibleColumns = {
    id: false,
    validFromCSV: false,
    validToCSV: false,
    tenant: false,
    createdAtCSV: false,
    priceCSV: false,
  }

  const csvHeaders = [
    {
      label: t('valid_from'),
      key: 'validFromCSV',
    },
    {
      label: t('valid_to'),
      key: 'validToCSV',
    },
    {
      label: t('product_name'),
      key: 'productName',
    },
    {
      label: t('car_plates'),
      key: 'carPlates',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'Tenant',
      key: 'tenant',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: t('purchase_date'),
      key: 'createdAtCSV',
    },
    {
      label: `${t('payment_value')} (PLN)`,
      key: 'priceCSV',
    },
  ]

  const columns: ColumnDef<ParkingSubscriptionRow, any>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'validFrom',
        header: t('valid_from'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validFromCSV',
        header: t('valid_from'),
      },
      {
        accessorKey: 'validTo',
        header: t('valid_to'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validToCSV',
        header: t('valid_to'),
      },
      {
        accessorKey: 'productName',
        header: t('product_name'),
        filterFn: 'equalsString',
      },
      {
        accessorKey: 'carPlates',
        header: t('car_plates'),
      },
      {
        accessorKey: 'login',
        header: 'Login',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'tenant',
        header: t('tenant'),
      },
      {
        accessorKey: 'status',
        header: t('status'),
        cell: (value) => t(`parking_subscription_status:${value.getValue()}`),
      },
      {
        accessorKey: 'createdAt',
        header: t('purchase_date'),
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'createdAtCSV',
        header: t('purchase_date'),
      },
      {
        accessorKey: 'priceCSV',
        header: t('payment_value'),
      },
    ],
    [t, lang],
  )

  const handleSelectRows = useCallback(
    (selectedRowsData: ParkingSubscriptionRow[]) => {
      setCSVData(selectedRowsData)
    },
    [],
  )

  const tableData: ParkingSubscriptionRow[] = useMemo(
    () => mapper(subscriptions) || [],
    [subscriptions],
  )

  useLayoutEffect(() => {
    if (params.subscriptionId !== ':subscriptionId') {
      setOpenedDetails(tableData.find(({ id }) => id === params.subscriptionId))
    }
  }, [params])

  return (
    <>
      <HeaderContainer>
        <Title variant="h6">{t('valid_subscriptions_list')}</Title>
        <DownloadCSVLink
          data={CSVData}
          csvHeaders={csvHeaders}
          fileName="parking-subscription-table.csv"
          filterCsvValue={t('bool:yes')}
          filterCsvKey="disabled"
          getData={queryParkingSubscriptions}
        />
      </HeaderContainer>
      <Table
        columns={columns}
        data={tableData}
        manualPagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        count={itemsCount}
        pageIndex={currentPage}
        setCurrentPage={setCurrentPage}
        manualSorting
        globalSearch={false}
        manualFiltering
        enableColumnFilters={false}
        loading={loading}
        columnVisibility={invisibleColumns}
        enableRowSelection
        onSelectionChange={handleSelectRows}
        onRowClick={(rowData: ParkingSubscriptionRow) => {
          rowData && setOpenedDetails(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'status'
            ? {
                style: {
                  color: getSubscriptionStatusColor(row.original.status),
                  fontWeight: 600,
                },
              }
            : {}
        }
        sortByKey="createdAt"
        sortDesc
      >
        <ParkingSubscriptionFilters
          minValidTo={minValidTo}
          setMinValidTo={setMinValidTo}
          filterKey={filterKey}
          setFilterKey={setFilterKey}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          setCurrentPage={setCurrentPage}
        />
      </Table>

      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        {openedDetails && (
          <SubscriptionDetails
            closeDetails={handleCloseDrawer}
            data={openedDetails}
            refetch={getFilteredParkingSubscriptions}
          />
        )}
      </BaseDrawer>
    </>
  )
}

export default SoldSubscriptionsList

const Title = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
