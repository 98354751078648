import gql from 'graphql-tag'
import { ParkingProduct } from './queryParkingSubscriptionPayment'

export interface IGetParkingPaymentVars {
  id: string
}

export interface IGetParkingPaymentData {
  parkingTicket: ParkingProduct
}

export const GET_PARKING_TICKET_PAYMENT = gql`
  query getParkingTicket($id: ID!) {
    parkingTicket: getParkingTicket(id: $id) {
      id
      buyer {
        city
        buyerName: name
        country
        nip
        street
        zipcode
      }
      payment {
        paymentId: id
        sessionId: session_id
        createdAt: created_at
        invoiceEmail: invoice_email
        status
        amount
        seller {
          name
          nip
          simplifiedInvoice: simplified_invoice {
            enabled
          }
        }
        sandbox
        logs(order: { desc: created_at }) {
          message
          createdAt: created_at
        }
      }
      carPlate: car_plate
      carPlate2: car_plate_2
      invoiceNeeded: invoice_needed
      parkingPass: parking_pass {
        price
        name
        nameEn: name_en
        parking {
          parkingName: name
          seller {
            name
            nip
            simplifiedInvoice: simplified_invoice {
              enabled
            }
          }
        }
        seller {
          name
          nip
          simplifiedInvoice: simplified_invoice {
            enabled
          }
        }
      }
      validFrom: valid_from
      validTo: valid_to
      user {
        accessCardId: access_card_id
        login
        firstName: first_name
        lastName: last_name
        tenant {
          tenantName: name
        }
      }
    }
  }
`

export const GET_ONE_TIME_PARKING_TICKET_PAYMENT = gql`
  query getParkingTicket($id: ID!) {
    parkingTicket: getOneTimeParkingTicket(id: $id) {
      id
      buyer {
        city
        buyerName: name
        country
        nip
        street
        zipcode
      }
      productSeller: seller {
        name
        nip
        simplifiedInvoice: simplified_invoice {
          enabled
        }
      }
      payment {
        paymentId: id
        sessionId: session_id
        createdAt: created_at
        invoiceEmail: invoice_email
        status
        sandbox
        amount
        logs(order: { desc: created_at }) {
          message
          createdAt: created_at
        }
      }
      duration
      carPlate: car_plate
      invoiceNeeded: invoice_needed
      parkingName: parking_name
      price
      validFrom: valid_from
      validTo: valid_to
      user {
        accessCardId: access_card_id
        login
        firstName: first_name
        lastName: last_name
        tenant {
          tenantName: name
        }
      }
    }
  }
`
