import { useEffect, useState } from 'react'
import { useLazyQuery, ApolloError } from '@apollo/client'
import {
  IUser,
  QUERY_USER,
  QUERY_USER_WITH_EMAIL,
  QUERY_USER_WITH_WORK_EMAIL,
  QUERY_USER_WITH_TENANT,
  QUERY_USER_WITH_CAR_PLATE,
  IQueryUsersData,
  IQueryUserVars,
  QUERY_USER_WITH_CARD_NUMBER,
} from '../graphql/users/queryUserWithPagination'
import { UserFilter } from '../screens/Users/UserListScreen'

export const useFetchFilteredUsers = (
  pageSize: number,
  currentPage: number,
  filterKey: UserFilter | '',
  filterValue: string,
  setError: (v: string) => void,
): {
  users: IUser[]
  loading: boolean
  itemsCount?: number
  refetch: () => void
} => {
  const [users, setUsers] = useState<IUser[]>([])
  const [itemsCount, setItemsCount] = useState<number | undefined>()

  const onError = (error: ApolloError) => setError(error.message)

  const onCompleted = async (data: IQueryUsersData) => {
    const { count } = data.aggregateUser
    await setItemsCount(count)
    await setUsers(data.queryUser)
  }

  const [queryUsers, { loading: usersLoading }] = useLazyQuery<
    IQueryUsersData,
    IQueryUserVars
  >(QUERY_USER, {
    fetchPolicy: 'no-cache',
    onCompleted,
    onError,
  })

  const [queryUserWithEmail, { loading: userWithEmailLoading }] = useLazyQuery<
    IQueryUsersData,
    IQueryUserVars
  >(QUERY_USER_WITH_EMAIL, {
    fetchPolicy: 'no-cache',
    onCompleted,
    onError,
  })
  const [queryUserWithWorkEmail, { loading: userWithWorkEmailLoading }] =
    useLazyQuery<IQueryUsersData, IQueryUserVars>(QUERY_USER_WITH_WORK_EMAIL, {
      fetchPolicy: 'no-cache',
      onCompleted,
      onError,
    })
  const [queryUserWithTenant, { loading: userWithTenantLoading }] =
    useLazyQuery<IQueryUsersData, IQueryUserVars>(QUERY_USER_WITH_TENANT, {
      fetchPolicy: 'no-cache',
      onCompleted,
      onError,
    })
  const [queryUserWithCarPlate, { loading: userWithCarPlateLoading }] =
    useLazyQuery<IQueryUsersData, IQueryUserVars>(QUERY_USER_WITH_CAR_PLATE, {
      fetchPolicy: 'no-cache',
      onCompleted,
      onError,
    })
  const [queryUserWithCardNumber, { loading: userWithCardNumberLoading }] =
    useLazyQuery<IQueryUsersData, IQueryUserVars>(QUERY_USER_WITH_CARD_NUMBER, {
      fetchPolicy: 'no-cache',
      onCompleted,
      onError,
    })

  const getFilteredUsers = () => {
    setError('')
    const vars = {
      first: pageSize,
      offset: currentPage * pageSize,
    }
    if (!filterValue) {
      queryUsers({
        variables: {
          ...vars,
        },
      })
    } else {
      switch (filterKey) {
        case UserFilter.login:
          queryUsers({
            variables: {
              ...vars,
              filter: { login: { eq: filterValue } },
            },
          })
          break
        case UserFilter.lastName:
          queryUsers({
            variables: {
              ...vars,
              filter: { last_name: { anyofterms: filterValue } },
            },
          })
          break
        case UserFilter.email:
          queryUserWithEmail({
            variables: {
              ...vars,
              email: filterValue,
            },
          })
          break
        case UserFilter.workEmail:
          queryUserWithWorkEmail({
            variables: {
              ...vars,
              workEmail: filterValue,
            },
          })
          break
        case UserFilter.tenant:
          queryUserWithTenant({
            variables: {
              ...vars,
              tenant: filterValue,
            },
          })
          break
        case UserFilter.carPlate:
          queryUserWithCarPlate({
            variables: {
              ...vars,
              carPlate: filterValue,
            },
          })
          break
        case UserFilter.cardNumber:
          queryUserWithCardNumber({
            variables: {
              ...vars,
              cardNumber: filterValue,
            },
          })
          break
      }
    }
  }

  useEffect(() => {
    getFilteredUsers()
  }, [filterKey, filterValue, currentPage, pageSize])

  const refetch = () => getFilteredUsers()

  const loading =
    usersLoading ||
    userWithEmailLoading ||
    userWithWorkEmailLoading ||
    userWithTenantLoading ||
    userWithCarPlateLoading ||
    userWithCardNumberLoading

  return {
    users,
    loading,
    itemsCount,
    refetch,
  }
}
