import { useState, useRef, useEffect } from 'react'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'
import GetAppIcon from '@mui/icons-material/GetApp'
import { useTranslation } from 'react-i18next'
import { BaseTextButton } from '../../components/Shared/BaseButton'

const DownloadCSVLink = ({
  csvHeaders,
  data,
  fileName = 'table-data-export.csv',
  filterCsvValue,
  filterCsvKey,
  getData,
}: {
  csvHeaders?: { label: string; key: string }[]
  data: any[]
  fileName?: string
  filterCsvValue?: string
  filterCsvKey?: string
  getData?: () => void
}) => {
  const { t } = useTranslation('table_component')
  const [loading, setLoading] = useState(false)
  const ref = useRef<any | null>(null)
  const csvFilteredData =
    filterCsvValue && filterCsvKey
      ? data.filter((row) => row[filterCsvKey] !== filterCsvValue)
      : data

  const handleDownloadCSV = () => {
    if (data.length) {
      ref.current.link.click()
    } else {
      setLoading(true)
      getData && getData()
    }
  }

  useEffect(() => {
    if (data.length && loading) {
      ref.current.link.click()
      setLoading(false)
    }
  }, [data])

  return (
    <>
      <ButtonCSV
        startIcon={<GetAppIcon />}
        loading={loading}
        onClick={handleDownloadCSV}
      >
        {t('download_csv')}
      </ButtonCSV>
      <CSVLinkStyled
        ref={ref}
        separator={';'}
        filename={fileName}
        data={csvFilteredData}
        headers={csvHeaders ? csvHeaders : undefined}
      />
    </>
  )
}

export default DownloadCSVLink

const CSVLinkStyled = styled(CSVLink)`
  text-decoration: none;
  display: none;
`
const ButtonCSV = styled(BaseTextButton)`
  margin: 16px 0;
`
