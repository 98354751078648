import { Grid, Chip, Stack, Button, Typography } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { themeColors } from '../../const/colors'
import { InputAdornment } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { BaseTextButton } from '../Shared/BaseButton'
import { useForm } from 'react-hook-form'
import Input from '../Shared/Input'
import { TextBody1Bold, TextBody2 } from '../Styles/TextCustom'
import { ParkingSubscriptionFilter } from '../../screens/Parking/SoldSubscriptionList'
import FilterSelect from '../Shared/FilterSelect'
import DatePicker from '../../components/Shared/DatePicker'
import BaseSelect from '../Shared/BaseSelect'
import { ParkingSubscriptionStatus } from '../../graphql/parking/queryParkingSubscriptions'

type FormData = {
  value: string
  status: {
    label: string
    value: string
  }
}

const ParkingSubscriptionFilters = ({
  minValidTo,
  setMinValidTo,
  setCurrentPage,
  filterKey,
  setFilterKey,
  filterValue,
  setFilterValue,
}: {
  minValidTo: Date | null
  setMinValidTo: (value: Date | null) => void
  setCurrentPage: (v: number) => void
  filterKey: ParkingSubscriptionFilter | ''
  setFilterKey: (v: ParkingSubscriptionFilter | '') => void
  filterValue: string
  setFilterValue: (v: string) => void
}) => {
  const { t } = useTranslation(['sold_parking_items'])
  const now = new Date(new Date().setHours(0, 0, 0, 0))
  const subscriptionStatus = filterKey === ParkingSubscriptionFilter.status
  const filters = [
    {
      name: ParkingSubscriptionFilter.productName,
    },
    {
      name: ParkingSubscriptionFilter.status,
    },
    {
      name: ParkingSubscriptionFilter.carPlate,
    },
    {
      name: ParkingSubscriptionFilter.login,
    },
    {
      name: ParkingSubscriptionFilter.email,
    },
  ]
  const statuses = [
    {
      value: ParkingSubscriptionStatus.active,
      label: t(
        `parking_subscription_status:${ParkingSubscriptionStatus.active}`,
      ),
    },
    {
      value: ParkingSubscriptionStatus.pending,
      label: t(
        `parking_subscription_status:${ParkingSubscriptionStatus.pending}`,
      ),
    },
    {
      value: ParkingSubscriptionStatus.new,
      label: t(`parking_subscription_status:${ParkingSubscriptionStatus.new}`),
    },
    {
      value: ParkingSubscriptionStatus.past,
      label: t(`parking_subscription_status:${ParkingSubscriptionStatus.past}`),
    },
    {
      value: ParkingSubscriptionStatus.cancelled,
      label: t(
        `parking_subscription_status:${ParkingSubscriptionStatus.cancelled}`,
      ),
    },
    {
      value: ParkingSubscriptionStatus.error,
      label: t(
        `parking_subscription_status:${ParkingSubscriptionStatus.error}`,
      ),
    },
  ]
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>()

  const handleClearFilters = async () => {
    await setFilterKey('')
    await setFilterValue('')
    await setMinValidTo(now)
    await reset()
  }

  const onSubmit = async (data: FormData) => {
    const { value, status } = data

    if (subscriptionStatus) {
      await setFilterValue(status.value)
    } else {
      await setFilterValue(value.trim())
    }
    await setCurrentPage(0)
  }

  const handleFilterKey = async (v: ParkingSubscriptionFilter) => {
    await setFilterValue('')
    await setFilterKey(v)
  }

  return (
    <Wrapper>
      <TextBody1Bold color={themeColors.primary}>
        {t('filter_title')}
      </TextBody1Bold>

      <Row>
        <div>
          <Subtitle>{t('valid_to_info')}</Subtitle>
          <DatePickerWrapper>
            <DatePicker
              selectedDate={minValidTo}
              setSelectedDate={setMinValidTo}
              label={t('valid_to')}
            />
          </DatePickerWrapper>
        </div>
        <CenterContainer>
          <FilterSelect
            value={filterKey}
            setValue={handleFilterKey}
            filters={filters}
          />
        </CenterContainer>

        {!subscriptionStatus && (
          <Container>
            <Input
              label={t('btn:search')}
              name="value"
              control={control}
              errors={errors}
              rules={{ required: !minValidTo && `${t('form:field_required')}` }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faSearch as IconProp}
                      size="lg"
                      color={themeColors.secondary}
                    />
                  </InputAdornment>
                ),
              }}
              disabled={!filterKey}
            />
          </Container>
        )}
        {subscriptionStatus && (
          <CenterContainer>
            <BaseSelect
              options={statuses}
              control={control}
              name="status"
              label="Status"
              placeholder="Wybierz status"
            />
          </CenterContainer>
        )}

        <SubmitButton
          onClick={handleSubmit(onSubmit)}
          disabled={!filterKey && !minValidTo}
        >
          {t('btn:submit_filters')}
        </SubmitButton>
      </Row>
      <Stack direction="row" spacing={1}>
        {filterValue && (
          <ChipStyled
            label={`${t(`user_search:${filterKey}`)}: ${filterValue}`}
          />
        )}
        {filterValue && (
          <ButtonStyled variant="text" onClick={handleClearFilters}>
            {t('btn:clear_filters')}
          </ButtonStyled>
        )}
      </Stack>
    </Wrapper>
  )
}

export default ParkingSubscriptionFilters

const Wrapper = styled(Grid)`
  background-color: ${themeColors.lightBlue};
  padding: 8px 16px;
  border-radius: 30px;
  margin-bottom: 16px;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`
const ChipStyled = styled(Chip)`
  color: ${themeColors.primary};
  background-color: ${themeColors.lightBlue};
  font-size: 14px;
  font-weight: 500;
`
const ButtonStyled = styled(Button)`
  text-transform: none;
  font-weight: 700;
  margin-bottom: 16px;
`
const Container = styled.div`
  flex: 1;
  padding: 0 16px;
  max-width: 500px;
`
const Subtitle = styled(TextBody2)`
  font-weight: 500;
  color: ${themeColors.primary};
  padding-bottom: 16px;
`
const DatePickerWrapper = styled(Grid)`
  background-color: ${themeColors.white};
  width: 242px;
  margin-bottom: 16px;
`
const CenterContainer = styled.div`
  padding: 0 0 16px 16px;
  min-width: 242px;
`
const SubmitButton = styled(BaseTextButton)`
  margin-bottom: 16px;
`
