import { useEffect, useState } from 'react'
import { ApolloError, useLazyQuery } from '@apollo/client'
import {
  ParkingProduct,
  IQueryParkingTicketPaymentVars,
  IQueryParkingTicketPaymentData,
  QUERY_PARKING_SUBSCRIPTION_PAYMENT,
  QUERY_PARKING_SUBSCRIPTION_PAYMENT_WITH_PAYMENT_FILTER,
  QUERY_PARKING_SUBSCRIPTION_PAYMENT_WITH_USER_FILTER,
} from '../graphql/parking/queryParkingSubscriptionPayment'
import {
  QUERY_ONE_TIME_PARKING_TICKET_PAYMENT,
  QUERY_ONE_TIME_PARKING_TICKET_PAYMENT_WITH_PAYMENT_FILTER,
  QUERY_ONE_TIME_PARKING_TICKET_PAYMENT_WITH_USER_FILTER,
} from '../graphql/parking/queryOneTimeParkingTicketPayment'
import { ParkingTicketPaymentFilter } from '../components/Parking/ParkingPaymentHistoryTable'
import { date2isoString } from '../utils/formatDate'
import { ParkingProductType } from '../screens/Parking/ParkingPaymentHistory'

export const useFetchFilteredParkingTicketPayments = (
  productType: ParkingProductType,
  pageSize: number,
  currentPage: number,
  filterKey: ParkingTicketPaymentFilter | '',
  filterValue: string,
  setError: (v: string) => void,
  params?: string,
): {
  parkingTicketsPayments: ParkingProduct[]
  loading: boolean
  itemsCount?: number
  getFilteredParkingTicketPayments: () => void
} => {
  const [parkingTicketsPayments, setParkingTicketsPayments] = useState<
    ParkingProduct[]
  >([])
  const [itemsCount, setItemsCount] = useState<number | undefined>()
  const subscription = productType === ParkingProductType.subscription

  const onCompleted = async (data: IQueryParkingTicketPaymentData) => {
    const { count } = data.aggregateParkingTicket
    await setItemsCount(count)
    await setParkingTicketsPayments(data.queryParkingTicket)
  }

  const onError = (error: ApolloError) => setError(error.message)

  const [
    queryParkingTicketPayments,
    { loading: parkingTicketPaymentsLoading },
  ] = useLazyQuery<
    IQueryParkingTicketPaymentData,
    IQueryParkingTicketPaymentVars
  >(
    subscription
      ? QUERY_PARKING_SUBSCRIPTION_PAYMENT
      : QUERY_ONE_TIME_PARKING_TICKET_PAYMENT,
    {
      fetchPolicy: 'no-cache',
      onCompleted,
      onError,
    },
  )

  const [
    queryParkingTicketPaymentsWithPaymentFilter,
    { loading: parkingTicketPaymentWithPaymentFilterLoading },
  ] = useLazyQuery<
    IQueryParkingTicketPaymentData,
    IQueryParkingTicketPaymentVars
  >(
    subscription
      ? QUERY_PARKING_SUBSCRIPTION_PAYMENT_WITH_PAYMENT_FILTER
      : QUERY_ONE_TIME_PARKING_TICKET_PAYMENT_WITH_PAYMENT_FILTER,
    {
      fetchPolicy: 'no-cache',
      onCompleted,
      onError,
    },
  )
  const [
    queryParkingTicketPaymentsWithUserFilter,
    { loading: parkingTicketPaymentWithUserFilterLoading },
  ] = useLazyQuery<
    IQueryParkingTicketPaymentData,
    IQueryParkingTicketPaymentVars
  >(
    subscription
      ? QUERY_PARKING_SUBSCRIPTION_PAYMENT_WITH_USER_FILTER
      : QUERY_ONE_TIME_PARKING_TICKET_PAYMENT_WITH_USER_FILTER,
    {
      fetchPolicy: 'no-cache',
      onCompleted,
      onError,
    },
  )

  const getFilteredParkingTicketPayments = () => {
    setError('')
    const vars = {
      first: pageSize,
      offset: currentPage * pageSize,
    }

    if (!filterValue && !params) {
      queryParkingTicketPayments({
        variables: {
          ...vars,
        },
      })
    } else {
      switch (filterKey) {
        case ParkingTicketPaymentFilter.carPlate:
          queryParkingTicketPayments({
            variables: {
              ...vars,
              filter: subscription
                ? {
                    or: [
                      {
                        car_plate: { anyofterms: filterValue },
                      },
                      {
                        car_plate_2: { anyofterms: filterValue },
                      },
                    ],
                  }
                : { car_plate: { anyofterms: filterValue } },
            },
          })
          break
        case ParkingTicketPaymentFilter.paymentId:
          queryParkingTicketPaymentsWithPaymentFilter({
            variables: {
              ...vars,
              paymentFilter: { session_id: { eq: filterValue } },
            },
          })
          break
        case ParkingTicketPaymentFilter.paymentStatus:
          queryParkingTicketPaymentsWithPaymentFilter({
            variables: {
              ...vars,
              paymentFilter: { status: { eq: filterValue } },
            },
          })
          break
        case ParkingTicketPaymentFilter.paymentDate:
          queryParkingTicketPaymentsWithPaymentFilter({
            variables: {
              ...vars,
              paymentFilter: {
                created_at: { ge: date2isoString(new Date(filterValue)) },
              },
            },
          })
          break
        case ParkingTicketPaymentFilter.login:
          queryParkingTicketPaymentsWithUserFilter({
            variables: {
              ...vars,
              userFilter: { login: { anyofterms: filterValue } },
            },
          })
          break
        case ParkingTicketPaymentFilter.lastName:
          queryParkingTicketPaymentsWithUserFilter({
            variables: {
              ...vars,
              userFilter: { last_name: { anyofterms: filterValue } },
            },
          })
          break
      }
    }
  }

  useEffect(() => {
    getFilteredParkingTicketPayments()
  }, [currentPage, pageSize, filterValue, productType])

  const loading =
    parkingTicketPaymentsLoading ||
    parkingTicketPaymentWithPaymentFilterLoading ||
    parkingTicketPaymentWithUserFilterLoading

  return {
    parkingTicketsPayments,
    loading,
    itemsCount,
    getFilteredParkingTicketPayments,
  }
}
