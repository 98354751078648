import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import {
  ANONYMIZE_USER,
  IAnonymizeUserData,
  IAnonymizeUserVars,
} from '../../graphql/users/anonymizeUser'
import { UserAnonymizeStatus } from '../../graphql/users/queryUserWithPagination'
import styled from 'styled-components'
import { TextBody2 } from '../Styles/TextCustom'
import { toast } from 'react-toastify'
import { Grid } from '@mui/material'
import { themeColors } from '../../const/colors'
import { IUser } from '../../graphql/users/queryUserWithPagination'
import { BaseTextButton } from '../Shared/BaseButton'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import ErrorContainer from '../Shared/ErrorContainer'
import { Tooltip } from '@mui/material'

const AnonymizeUser: FC<{
  userData: IUser
  refetch: () => void
}> = ({ userData, refetch }) => {
  const { login, anonymizeStatus, disabled, firstName, lastName } = userData
  const { t } = useTranslation(['user_details'])
  const [anonymize, setAnonymize] = useState(!!anonymizeStatus)
  const [openModal, setOpenModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const successNotify = (message: string) => toast.dark(message)

  const anonymized = anonymizeStatus === UserAnonymizeStatus.anonymized

  const onCompleted = (data: IAnonymizeUserData) => {
    const { status, error, code } = data.anonymizeUser
    if (status) {
      successNotify(t('anonymize_success'))
      refetch()
    } else
      error &&
        setErrorMessage(
          t(`code:${code}`) === `${code}` ? `${error}` : t(`code:${code}`),
        )
  }

  const [anonymizeUser] = useMutation<IAnonymizeUserData, IAnonymizeUserVars>(
    ANONYMIZE_USER,
    {
      onCompleted,
      variables: {
        login: login,
        anonymize: !anonymize,
      },
    },
  )

  useEffect(() => {
    const anonymizedStatuses = [
      UserAnonymizeStatus.anonymized,
      UserAnonymizeStatus.to_anonymize,
    ]
    setAnonymize(!!anonymizedStatuses.includes(anonymizeStatus))
  }, [anonymizeStatus])

  const handleUserAnonymize = () => {
    if (anonymize) {
      anonymizeUser()
    } else {
      setOpenModal(true)
    }
  }

  const handleAcceptModal = () => {
    anonymizeUser()
    setOpenModal(false)
  }

  return (
    <Container container alignItems={'flex-start'}>
      <Tooltip disableHoverListener={disabled} title={t('anonymize_info')}>
        <span>
          <BaseTextButton
            disabled={!disabled || anonymized}
            onClick={handleUserAnonymize}
            color={
              !disabled
                ? themeColors.gray
                : anonymize
                  ? themeColors.primary
                  : themeColors.error
            }
          >
            {anonymize ? t('restore_user') : t('anonymize')}
          </BaseTextButton>
        </span>
      </Tooltip>
      {anonymize && (
        <TextStyled color={themeColors.error}>{t('anonymized')}</TextStyled>
      )}
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      <BaseModal
        confirmText={'btn:confirm'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleAccept={handleAcceptModal}
      >
        <ModalContent>{`${t(
          'anonymize_confirmation',
        )}${firstName} ${lastName}?`}</ModalContent>
      </BaseModal>
    </Container>
  )
}

export default AnonymizeUser
const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
`
const TextStyled = styled(TextBody2)`
  padding: 0 16px;
`
