import styled from 'styled-components'
import { Grid } from '@mui/material'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import { useTranslation } from 'react-i18next'
import { dateIso2localeString } from '../../utils/formatDate'
import { IParkingTicket } from '../../graphql/parking/queryParkingSubscriptions'
import PaymentIcon from '@mui/icons-material/Payment'
import { getPaymentStatusColor } from '../../utils/getStatusColor'
import { options } from './SubscriptionDetails'
import LogsContainer from '../Shared/LogsContainer'
import { ROUTES } from '../../const/routes'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import NewTabLink from '../Shared/NewTabLink'

const ParkingTicketDetails = ({ ticket }: { ticket: IParkingTicket }) => {
  const { validFrom, validTo, payment, reservations, saleId, id } = ticket
  const { t, i18n } = useTranslation(['sold_parking_items'])
  const lang = i18n.language
  const formatPrice = (price: number) => `${(price * 0.01).toFixed(2)} PLN`

  const details = [
    {
      label: t('ticket'),
      value: `${dateIso2localeString(
        validFrom,
        lang,
        options,
      )} - ${dateIso2localeString(validTo, lang, options)}`,
    },
    {
      label: t('place'),
      value:
        reservations.length && !!reservations[0].place
          ? reservations[0].place?.name
          : undefined,
    },
    {
      label: t('payment_amount'),
      value: formatPrice(payment.amount),
    },
    { label: t('payment_external_id'), value: payment.externalId },
    { label: t('payment_id'), value: payment.sessionId },
    { label: t('sale_id'), value: saleId },
    {
      label: t('payment_created_at'),
      value: dateIso2localeString(payment.createdAt, lang, options),
    },
  ]

  return (
    <DetailsWrapper>
      <Grid container>
        <Grid item xs={1}>
          <PaymentIcon />
        </Grid>
        <Grid item xs={11}>
          {details.map(
            ({ label, value }) =>
              value && (
                <Row key={label}>
                  <Text>{`${label}: `}</Text>
                  <TextBold>{value}</TextBold>
                </Row>
              ),
          )}
          <Grid>
            <Text>{`${t('payment_status')}: `}</Text>
            <Status color={getPaymentStatusColor(payment.status)}>
              {t(`payment_status:${payment.status}`)}
            </Status>
          </Grid>
          <Grid>
            <Row>
              <NewTabLink
                path={`/admin/${ROUTES.PARKING_PAYMENT_HISTORY}/paymentId:${payment.sessionId}:subscription`}
                text={t('payment_history:details_title')}
              />
            </Row>
          </Grid>
        </Grid>

        {!!payment.logs.length ? (
          <LogsContainer logs={payment.logs} small />
        ) : (
          <TextBold>{t('no_logs')}</TextBold>
        )}
      </Grid>
    </DetailsWrapper>
  )
}

export default ParkingTicketDetails

const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const Status = styled(TextBody2Bold)`
  text-transform: uppercase;
  display: inline;
`
const Row = styled(Grid)`
  padding: 2px;
`
