import { gql } from '@apollo/client'
import { IUser } from './queryUserWithPagination'

export interface IGetUserVars {
  login: string
}

export interface IGetUsersData {
  getUser: IUser
}

export const GET_USER_MOBILE_PHONE = gql`
  query getUser($login: String!) {
    getUser(login: $login) {
      login
      mobilePhone: mobile_phone
    }
  }
`
