import Input from '../Shared/Input'
import { BaseButton } from '../Shared/BaseButton'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, ApolloError } from '@apollo/client'
import {
  AccessCardType,
  ISetAccessCardForUserData,
  ISetAccessCardForUserVars,
  SET_ACCESS_CARD_FOR_USER,
} from '../../graphql/users/setAccessCardForUser'
import { Title } from '../Styles/TextCustom'
import { useEffect, useState } from 'react'
import BaseSelect from '../Shared/BaseSelect'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { BaseTextButton } from '../Shared/BaseButton'
import { IUser } from '../../graphql/users/queryUserWithPagination'
import { Grid } from '@mui/material'
import { themeColors } from '../../const/colors'
import ErrorContainer from '../Shared/ErrorContainer'

type FormData = {
  cardNumber: string
  cardType: AccessCardType
}

interface IProps {
  userData: IUser
  refetch: () => void
}

const UserAccessCard = ({ userData, refetch }: IProps) => {
  const { t } = useTranslation(['user_details', 'tenant'])
  const { accessCard, login, tenant } = userData
  const [cardType, setCardType] = useState<AccessCardType>()
  const successNotify = (message: string) => toast.dark(message)
  const [show, setShow] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState('')

  const seos = tenant?.seos ? !tenant?.seos?.disabled : false

  const onSetAccessCardCompleted = (data: ISetAccessCardForUserData) => {
    const { status, error, code } = data.setAccessCardForUser
    if (status) {
      setErrorMessage('')
      refetch()
      successNotify(`${t('user_details:access_card_change_success')}`)
    } else
      error &&
        setErrorMessage(
          t(`code:${code}`) === `${code}` ? `${error}` : t(`code:${code}`),
        )
  }

  const [setAccessCardForUser] = useMutation<
    ISetAccessCardForUserData,
    ISetAccessCardForUserVars
  >(SET_ACCESS_CARD_FOR_USER, {
    onCompleted: onSetAccessCardCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FormData>()

  useEffect(() => {
    if (accessCard) {
      setCardType(accessCard.type)
      setValue('cardNumber', accessCard.number)
    } else {
      setCardType(undefined)
      setValue('cardNumber', '')
    }
  }, [accessCard])
  const updateUserAccessCard = async (data: FormData) => {
    if (cardType && data.cardNumber) {
      await setAccessCardForUser({
        variables: {
          login,
          cardNumber: data.cardNumber,
          cardType: cardType,
        },
      })
    }
  }

  const handleSelectChange = (option: any) => setCardType(option?.value)

  const cardTypes = seos
    ? [{ value: AccessCardType.seos, label: AccessCardType.seos }]
    : [
        {
          value: AccessCardType.prox816,
          label: AccessCardType.prox816,
        },
        {
          value: AccessCardType.prox40,
          label: AccessCardType.prox40,
        },
      ]

  return (
    <BlueContainer item xs={6}>
      <Row>
        <Title>{t('access_card')}</Title>
        <BaseTextButton onClick={() => setShow(!show)}>
          {show ? t('btn:collapse') : t('btn:expand')}
        </BaseTextButton>
      </Row>

      {show && (
        <>
          <InputContainer>
            <BaseSelect
              placeholder={t('card_type')}
              name="cardType"
              options={cardTypes}
              value={{ value: cardType, label: cardType }}
              onChange={handleSelectChange}
            />
          </InputContainer>
          <InputContainer>
            <Input
              label={'Card number'}
              name={'cardNumber'}
              control={control}
              errors={errors}
            />
          </InputContainer>
          <BaseButton onClick={handleSubmit(updateUserAccessCard)}>
            {t('btn:save')}
          </BaseButton>
          {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
        </>
      )}
    </BlueContainer>
  )
}

export default UserAccessCard

const InputContainer = styled.div`
  margin: 16px 0;
  max-width: 500px;
`
const BlueContainer = styled(Grid)`
  background-color: ${themeColors.lightBlue};
  border-radius: 20px;
  padding: 20px;
  margin-top: 16px;
  margin-left: 8px;
  flex: 1;
`
const Row = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
