import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import BaseSelect from '../Shared/BaseSelect'
import { GET_EVENTS, IGetEventsData } from '../../graphql/events/getEvents'
import { useQuery } from '@apollo/client'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { TextBody1 } from '../Styles/TextCustom'

const EventSelect = ({
  control,
  eventId,
  setValue,
}: {
  control: Object
  eventId?: string
  setValue: any
}) => {
  const { t } = useTranslation(['extras'])
  const { data, loading } = useQuery<IGetEventsData>(GET_EVENTS, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (eventId) {
      const event = data?.getEvents.events.find(
        ({ id }) => id === Number(eventId),
      )
      event && setValue('eventId', { value: event.id, label: event.title })
    }
  }, [data, eventId])

  if (loading) {
    return <FullScreenLoader />
  }

  if (!data) {
    return null
  }

  return (
    <div data-e2e="event-select">
      <TextBody1>{t('choose_related_event')}</TextBody1>
      <BaseSelect
        options={data.getEvents.events.map(({ id, title }) => ({
          value: id,
          label: title,
        }))}
        control={control}
        name={'eventId'}
        label={'Event'}
        placeholder={t('choose_event')}
      />
    </div>
  )
}

export default EventSelect
