import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloError, useMutation } from '@apollo/client'
import {
  DISABLE_USER,
  IDisableUserVars,
  IDisableUserData,
} from '../../graphql/users/disableUser'
import { toast } from 'react-toastify'
import { BaseTextButton } from '../Shared/BaseButton'
import { themeColors } from '../../const/colors'
import { IUser } from '../../graphql/users/queryUserWithPagination'
import { UserAnonymizeStatus } from '../../graphql/users/queryUserWithPagination'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import ErrorContainer from '../Shared/ErrorContainer'

const BlockUser: FC<{
  userData: IUser
  refetch: () => void
  modalConfirmation?: boolean
}> = ({ userData, refetch, modalConfirmation }) => {
  const { t } = useTranslation(['user_details', 'code'])
  const [openModal, setOpenModal] = useState(false)
  const { login, anonymizeStatus, disabled, firstName, lastName } = userData
  const successNotify = (message: string) => toast.dark(message)
  const [errorMessage, setErrorMessage] = useState('')
  const anonymizedStatuses = [
    UserAnonymizeStatus.anonymized,
    UserAnonymizeStatus.to_anonymize,
  ]
  const anonymized = !!anonymizedStatuses.includes(anonymizeStatus)

  const onEditLockUserCompleted = (data: IDisableUserData) => {
    const { status, error, code } = data.disableUser
    if (status) {
      successNotify(`${disabled ? t('lock_success') : t('unlock_success')}`)
      refetch()
    } else
      error &&
        setErrorMessage(
          t(`code:${code}`) === `${code}` ? `${error}` : t(`code:${code}`),
        )
  }

  const [disableUser] = useMutation<IDisableUserData, IDisableUserVars>(
    DISABLE_USER,
    {
      onCompleted: onEditLockUserCompleted,
      onError: (error: ApolloError) => setErrorMessage(error.message),
      variables: {
        login: login,
        disable: !disabled,
      },
    },
  )

  const handleUserLockChange = () => {
    if (modalConfirmation && !disabled) {
      setOpenModal(true)
    } else {
      disableUser()
    }
  }

  const handleAcceptModal = () => {
    disableUser()
    setOpenModal(false)
  }

  return (
    <div data-e2e="block-user">
      <BaseTextButton
        onClick={handleUserLockChange}
        color={
          anonymized
            ? themeColors.gray
            : disabled
              ? themeColors.primary
              : themeColors.error
        }
        disabled={anonymized}
      >
        {disabled ? t('unblock_user') : t('block_user')}
      </BaseTextButton>
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      <BaseModal
        confirmText={'btn:confirm'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleAccept={handleAcceptModal}
      >
        <ModalContent>{`${t(
          'block_user_confirmation',
        )}${firstName} ${lastName}?`}</ModalContent>
      </BaseModal>
    </div>
  )
}

export default BlockUser
