import gql from 'graphql-tag'
import { IUser } from './queryUserWithPagination'

export interface IQueryUserRolesData {
  queryUser: IUser[]
}

export const QUERY_USER_ROLES_LIST = gql`
  query queryUserRolesList {
    queryUser @cascade(fields: ["roles"]) {
      login
      firstName: first_name
      lastName: last_name
      disabled
      tenant {
        name
        id
      }
      roles {
        name
      }
    }
  }
`
