import styled from 'styled-components'
import { FormControl, MenuItem, InputLabel } from '@mui/material'
import Select from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import { TextBody1Medium } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'

const FilterSelect = ({
  value,
  setValue,
  filters,
}: {
  value: any
  setValue: (v: any) => void
  filters: { name: any }[]
}) => {
  const { t } = useTranslation(['user_search'])

  const handleChange = (event: any) => setValue(event.target.value)

  return (
    <FormControl>
      <InputLabel id="select-label">{t('choose_filter')}</InputLabel>
      <SelectStyled
        labelId="select-label"
        value={value}
        label={t('choose_filter')}
        onChange={handleChange}
        data-e2e="filter-select"
      >
        {filters.map(({ name }, i) => (
          <MenuItem key={i} value={name}>
            <Text>{t(name)}</Text>
          </MenuItem>
        ))}
      </SelectStyled>
    </FormControl>
  )
}

export default FilterSelect

const SelectStyled = styled(Select)`
  background-color: ${themeColors.white};
  width: 242px;
`
const Text = styled(TextBody1Medium)`
  margin: 0;
`
