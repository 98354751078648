import { useEffect, useState } from 'react'
import { ApolloError, useLazyQuery } from '@apollo/client'
import {
  ISubscription,
  IQuerySubscriptionVars,
  IQuerySubscriptionData,
  QUERY_PARKING_SUBSCRIPTIONS,
  QUERY_PARKING_SUBSCRIPTIONS_WITH_PRODUCT_NAME,
  QUERY_PARKING_SUBSCRIPTIONS_WITH_LOGIN,
  QUERY_PARKING_SUBSCRIPTIONS_WITH_EMAIL,
} from '../graphql/parking/queryParkingSubscriptions'
import { ParkingSubscriptionFilter } from '../screens/Parking/SoldSubscriptionList'
import { date2isoString } from '../utils/formatDate'

export const useFetchFilteredParkingSubscriptions = (
  minValidTo: Date | null,
  pageSize: number,
  currentPage: number,
  filterKey: ParkingSubscriptionFilter | '',
  filterValue: string,
  setError: (v: string) => void,
): {
  subscriptions: ISubscription[]
  loading: boolean
  itemsCount?: number
  getFilteredParkingSubscriptions: () => void
} => {
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([])
  const [itemsCount, setItemsCount] = useState<number | undefined>()

  const onCompleted = async (data: IQuerySubscriptionData) => {
    const { count } = data.aggregateParkingSubscription
    await setItemsCount(count)
    await setSubscriptions(data.subscriptions)
  }

  const onError = (error: ApolloError) => setError(error.message)

  const [queryParkingSubscription, { loading: subscriptionsLoading }] =
    useLazyQuery<IQuerySubscriptionData, IQuerySubscriptionVars>(
      QUERY_PARKING_SUBSCRIPTIONS,
      {
        fetchPolicy: 'no-cache',
        onCompleted,
        onError,
      },
    )

  const [
    queryParkingSubscriptionWithProductName,
    { loading: subscriptionsWithProductNameLoading },
  ] = useLazyQuery<IQuerySubscriptionData, IQuerySubscriptionVars>(
    QUERY_PARKING_SUBSCRIPTIONS_WITH_PRODUCT_NAME,
    {
      fetchPolicy: 'no-cache',
      onCompleted,
      onError,
    },
  )
  const [
    queryParkingSubscriptionWithLogin,
    { loading: subscriptionsWithLoginLoading },
  ] = useLazyQuery<IQuerySubscriptionData, IQuerySubscriptionVars>(
    QUERY_PARKING_SUBSCRIPTIONS_WITH_LOGIN,
    {
      fetchPolicy: 'no-cache',
      onCompleted,
      onError,
    },
  )
  const [
    queryParkingSubscriptionWithEmail,
    { loading: subscriptionsWithEmailLoading },
  ] = useLazyQuery<IQuerySubscriptionData, IQuerySubscriptionVars>(
    QUERY_PARKING_SUBSCRIPTIONS_WITH_EMAIL,
    {
      fetchPolicy: 'no-cache',
      onCompleted,
      onError,
    },
  )

  const getFilteredParkingSubscriptions = () => {
    setError('')
    const vars = {
      first: pageSize,
      offset: currentPage * pageSize,
    }

    if (!minValidTo) {
      return null
    }
    const minDateFilter = { valid_to: { ge: date2isoString(minValidTo) } }
    if (!filterValue) {
      queryParkingSubscription({
        variables: {
          ...vars,
          filter: minDateFilter,
        },
      })
    } else {
      switch (filterKey) {
        case ParkingSubscriptionFilter.status:
          queryParkingSubscription({
            variables: {
              ...vars,
              filter: {
                ...minDateFilter,
                status: { eq: filterValue },
              },
            },
          })
          break
        case ParkingSubscriptionFilter.carPlate:
          queryParkingSubscription({
            variables: {
              ...vars,
              filter: {
                or: [
                  {
                    ...minDateFilter,
                    car_plate: { anyofterms: filterValue },
                  },
                  {
                    ...minDateFilter,
                    car_plate_2: { anyofterms: filterValue },
                  },
                ],
              },
            },
          })
          break
        case ParkingSubscriptionFilter.productName:
          queryParkingSubscriptionWithProductName({
            variables: {
              ...vars,
              filter: minDateFilter,
              productName: filterValue,
            },
          })
          break
        case ParkingSubscriptionFilter.login:
          queryParkingSubscriptionWithLogin({
            variables: {
              ...vars,
              filter: minDateFilter,
              login: filterValue,
            },
          })
          break
        case ParkingSubscriptionFilter.email:
          queryParkingSubscriptionWithEmail({
            variables: {
              ...vars,
              filter: minDateFilter,
              email: filterValue,
            },
          })
          break
      }
    }
  }

  useEffect(() => {
    if (minValidTo) {
      getFilteredParkingSubscriptions()
    }
  }, [currentPage, pageSize, filterValue])

  const loading =
    subscriptionsLoading ||
    subscriptionsWithProductNameLoading ||
    subscriptionsWithLoginLoading ||
    subscriptionsWithEmailLoading

  return {
    subscriptions,
    loading,
    itemsCount,
    getFilteredParkingSubscriptions,
  }
}
