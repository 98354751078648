import gql from 'graphql-tag'
import { IPayment, ILog } from '../shared/sharedTypes'

export enum ParkingSubscriptionStatus {
  active = 'active',
  cancelled = 'cancelled',
  error = 'error',
  new = 'new',
  past = 'past',
  pending = 'pending',
}
export interface IParkingTicket {
  id: string
  saleId: string
  validFrom: string
  validTo: string
  carPlate: string
  carPlate2: string
  payment: IPayment
  reservations: {
    place: {
      name: string
      shared: boolean
      info: {
        message: string
      }
    }
  }[]
}
export interface ISubscription {
  id: string
  status: ParkingSubscriptionStatus
  amanoCardNo: number
  validFrom: string
  validTo: string
  createdAt: string
  carPlate: string
  carPlate2: string
  parkingPass: {
    name: string
    nameEn: string
    parking: {
      name: string
      operator: string
    }
    tenant: {
      name: string
    }
  }
  activeParkingTicket: IParkingTicket
  pendingParkingTicket: IParkingTicket
  logs: ILog[]
  tickets: IParkingTicket[]
  user: {
    login: string
    email: {
      email: string
    }
    tenant: {
      name: string
    }
  }
}

type FilterType =
  | { [key: string]: { anyofterms: string } }
  | { [key: string]: { eq: string } }
  | { [key: string]: { ge: string } }
  | any

export interface IQuerySubscriptionVars {
  first?: number
  offset?: number
  filter?: FilterType | { or: FilterType[] }
  productName?: string
  login?: string
  email?: string
}

export interface IQuerySubscriptionData {
  subscriptions: ISubscription[]
  aggregateParkingSubscription: {
    count: number
  }
}

export const SUBSCRIPTION_FRAGMENT = gql`
  fragment SubscriptionParts on ParkingSubscription {
    id
    status
    validFrom: valid_from
    validTo: valid_to
    createdAt: created_at
    activeParkingTicket: active_parking_ticket {
      payment {
        id
        amount
      }
      carPlate: car_plate
      carPlate2: car_plate_2
    }
    pendingParkingTicket: pending_parking_ticket {
      payment {
        id
        amount
      }
      carPlate: car_plate
      carPlate2: car_plate_2
    }
    tickets: parking_tickets {
      carPlate: car_plate
      carPlate2: car_plate_2
    }
  }
`

export const QUERY_PARKING_SUBSCRIPTIONS = gql`
  ${SUBSCRIPTION_FRAGMENT}
  query queryParkingSubscription(
    $first: Int
    $offset: Int
    $filter: ParkingSubscriptionFilter
  ) {
    subscriptions: queryParkingSubscription(
      first: $first
      offset: $offset
      order: { desc: created_at }
      filter: $filter
    ) {
      ...SubscriptionParts
      parkingPass: parking_pass {
        name
        nameEn: name_en
      }
      user {
        login
        email {
          email
        }
        tenant {
          name
        }
      }
    }
    aggregateParkingSubscription(filter: $filter) {
      count
    }
  }
`

export const QUERY_PARKING_SUBSCRIPTIONS_WITH_PRODUCT_NAME = gql`
  ${SUBSCRIPTION_FRAGMENT}
  query queryParkingSubscription(
    $first: Int
    $offset: Int
    $filter: ParkingSubscriptionFilter
    $productName: String!
  ) {
    subscriptions: queryParkingSubscription(
      first: $first
      offset: $offset
      order: { desc: created_at }
      filter: $filter
    ) @cascade(fields: ["parking_pass"]) {
      ...SubscriptionParts
      parkingPass: parking_pass(
        filter: { name: { anyofterms: $productName } }
      ) {
        name
        nameEn: name_en
      }
      user {
        login
        email {
          email
        }
        tenant {
          name
        }
      }
    }
    aggregateParkingSubscription(filter: $filter) {
      count
    }
  }
`

export const QUERY_PARKING_SUBSCRIPTIONS_WITH_LOGIN = gql`
  ${SUBSCRIPTION_FRAGMENT}
  query queryParkingSubscription(
    $first: Int
    $offset: Int
    $filter: ParkingSubscriptionFilter
    $login: String!
  ) {
    subscriptions: queryParkingSubscription(
      first: $first
      offset: $offset
      order: { desc: created_at }
      filter: $filter
    ) @cascade(fields: ["user"]) {
      ...SubscriptionParts
      parkingPass: parking_pass {
        name
        nameEn: name_en
      }
      user(filter: { login: { anyofterms: $login } }) {
        login
        email {
          email
        }
        tenant {
          name
        }
      }
    }
    aggregateParkingSubscription(filter: $filter) {
      count
    }
  }
`
export const QUERY_PARKING_SUBSCRIPTIONS_WITH_EMAIL = gql`
  ${SUBSCRIPTION_FRAGMENT}
  query queryParkingSubscription(
    $first: Int
    $offset: Int
    $filter: ParkingSubscriptionFilter
    $email: String
  ) {
    subscriptions: queryParkingSubscription(
      first: $first
      offset: $offset
      order: { desc: created_at }
      filter: $filter
    ) @cascade(fields: ["user"]) {
      ...SubscriptionParts
      parkingPass: parking_pass {
        name
        nameEn: name_en
      }
      user @cascade(fields: ["email"]) {
        login
        email(filter: { email: { anyofterms: $email } }) {
          email
        }
        tenant {
          name
        }
      }
    }
    aggregateParkingSubscription(filter: $filter) {
      count
    }
  }
`

export interface IQuerySubscriptionCSVVars {
  minValidTo: string
}

export const QUERY_PARKING_SUBSCRIPTIONS_CSV = gql`
  ${SUBSCRIPTION_FRAGMENT}
  query queryParkingSubscription($minValidTo: DateTime) {
    subscriptions: queryParkingSubscription(
      filter: { valid_to: { ge: $minValidTo } }
    ) {
      ...SubscriptionParts
      parkingPass: parking_pass {
        name
        nameEn: name_en
      }
      user {
        login
        email {
          email
        }
        tenant {
          name
        }
      }
    }
  }
`
