import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import { TextButton } from '../Styles/TextCustom'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { themeColors } from '../../const/colors'
import { styled } from '@mui/material/styles'
import { CircularProgress } from '@mui/material'

interface StyledButtonProps extends ButtonProps {
  customColor?: string
}

const BaseButtonStyled = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<StyledButtonProps>(({ customColor }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  lineHeight: 1.25,
  width: '100%',
  maxWidth: 325,
  borderRadius: 100,
  backgroundColor: themeColors.primary,
  '&:hover': {
    backgroundColor: themeColors.lightPrimary,
    boxShadow: 'none',
  },
  ...(customColor && {
    backgroundColor: customColor,
    '&:hover': {
      backgroundColor: customColor,
    },
  }),
}))

const BaseButtonOutlinedStyled = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  lineHeight: 1.25,
  width: '100%',
  maxWidth: 325,
  borderRadius: 100,
  backgroundColor: themeColors.white,
  borderColor: themeColors.primary,
  borderWidth: 2,
  '&:hover': {
    backgroundColor: themeColors.white,
    borderColor: '#8CB1D1',
    boxShadow: 'none',
    borderWidth: 2,
  },
})

const BaseTextButtonStyled = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  lineHeight: 1.25,
  padding: '6px 16px',
  borderRadius: 100,
  color: themeColors.primary,
  '&:hover': {
    color: '#8CB1D1',
  },
})

export const BaseButton = ({
  children,
  onClick,
  type,
  icon,
  disabled,
  customColor,
  loading = false,
  ...rest
}: {
  children: any
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset' | undefined
  icon?: IconProp
  disabled?: boolean
  customColor?: string
  loading?: boolean
}) => {
  return (
    <BaseButtonStyled
      onClick={onClick}
      disabled={disabled}
      variant="contained"
      type={type}
      customColor={customColor}
      startIcon={icon && <FontAwesomeIcon icon={icon} />}
      disableElevation
      {...rest}
    >
      {loading ? (
        <CircularProgressStyled size={38} />
      ) : (
        <TextButton> {children}</TextButton>
      )}
    </BaseButtonStyled>
  )
}

export const BaseButtonOutlined = ({
  children,
  onClick,
  type,
  icon,
  disabled,
  ...rest
}: {
  children: any
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset' | undefined
  icon?: IconProp
  disabled?: boolean
}) => {
  return (
    <BaseButtonOutlinedStyled
      onClick={onClick}
      disabled={disabled}
      variant="outlined"
      type={type}
      color="primary"
      startIcon={icon && <FontAwesomeIcon icon={icon} />}
      disableElevation
      {...rest}
    >
      <TextButton color={themeColors.primary}> {children}</TextButton>
    </BaseButtonOutlinedStyled>
  )
}

export const BaseTextButton = ({
  children,
  onClick,
  type,
  icon,
  disabled,
  color,
  loading = false,
  ...rest
}: {
  children: any
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset' | undefined
  icon?: IconProp
  disabled?: boolean
  color?: string
  loading?: boolean
  [k: string]: any
}) => {
  return (
    <BaseTextButtonStyled
      onClick={onClick}
      disabled={disabled}
      color="primary"
      startIcon={
        icon && (
          <FontAwesomeIcon
            icon={icon}
            color={color ? color : themeColors.primary}
          />
        )
      }
      {...rest}
    >
      {loading ? (
        <CircularProgressStyled size={25} $bgcolor={themeColors.primary} />
      ) : (
        <TextButton color={color ? color : themeColors.primary}>
          {children}
        </TextButton>
      )}
    </BaseTextButtonStyled>
  )
}

const CircularProgressStyled = styled(CircularProgress)<{ $bgcolor?: string }>`
  color: ${themeColors.white};
  color: ${(props) => props.$bgcolor};
  margin: 6px;
`
