import gql from 'graphql-tag'
import { IPayment } from '../shared/sharedTypes'

export interface ISeller {
  name: string
  nip: string
  simplifiedInvoice: {
    enabled: boolean
  }
}

export interface ParkingProduct {
  id: string
  parkingPass?: {
    price: number
    name: string
    nameEn: string
    parking: {
      parkingName: string
      seller: ISeller
    }
    seller?: ISeller
  }
  price?: number
  parkingName?: string
  buyer?: {
    city: string
    buyerName: string
    country: string
    nip: string
    street: string
    zipcode: string
  }
  productSeller?: ISeller
  payment: IPayment
  carPlate: string
  carPlate2?: string
  invoiceNeeded: boolean
  validFrom: string
  validTo: string
  user: {
    login: string
    firstName: string
    lastName: string
    accessCardId: string
    tenant?: {
      tenantName: string
    }
  }
  duration?: string
  createdAt?: string
}

export interface IQueryParkingTicketPaymentData {
  queryParkingTicket: ParkingProduct[]
  aggregateParkingTicket: {
    count: number
  }
}

export type FilterType =
  | { [key: string]: { anyofterms: string } }
  | { [key: string]: { eq: string } }
  | { [key: string]: { ge: string } }
  | any

export interface IQueryParkingTicketPaymentVars {
  first?: number
  offset?: number
  filter?: FilterType
  paymentFilter?: FilterType
  userFilter?: FilterType
}

export const PARKING_SUBSCRIPTION_FRAGMENT = gql`
  fragment ParkingTicketParts on ParkingTicket {
    id
    parking_subscription(filter: { type: { eq: Personal } }) {
      type
    }
    buyer {
      city
      buyerName: name
      country
      nip
      street
      zipcode
    }
    parkingPass: parking_pass {
      price
      name
      parking {
        parkingName: name
      }
    }
    invoiceNeeded: invoice_needed
    validFrom: valid_from
    validTo: valid_to
    createdAt: created_at
  }
`

export const QUERY_PARKING_SUBSCRIPTION_PAYMENT = gql`
  ${PARKING_SUBSCRIPTION_FRAGMENT}
  query queryParkingTicket(
    $first: Int
    $offset: Int
    $filter: ParkingTicketFilter
  ) {
    queryParkingTicket(
      first: $first
      offset: $offset
      order: { desc: created_at }
      filter: $filter
    ) @cascade(fields: ["parking_subscription"]) {
      ...ParkingTicketParts
      payment {
        paymentId: session_id
        createdAt: created_at
        status
        sandbox
        amount
      }
      carPlate: car_plate
      carPlate2: car_plate_2
      user {
        login
        firstName: first_name
        lastName: last_name
        tenant {
          tenantName: name
        }
      }
    }
    aggregateParkingTicket(filter: $filter) {
      count
    }
  }
`

export const QUERY_PARKING_SUBSCRIPTION_PAYMENT_WITH_PAYMENT_FILTER = gql`
  ${PARKING_SUBSCRIPTION_FRAGMENT}
  query queryParkingTicket(
    $first: Int
    $offset: Int
    $paymentFilter: PaymentFilter
  ) {
    queryParkingTicket(first: $first, offset: $offset)
      # order: { desc: created_at }
      @cascade(fields: ["parking_subscription", "payment"]) {
      ...ParkingTicketParts
      payment(filter: $paymentFilter) {
        paymentId: session_id
        createdAt: created_at
        status
        sandbox
        amount
      }
      carPlate: car_plate
      carPlate2: car_plate_2
      user {
        login
        firstName: first_name
        lastName: last_name
        tenant {
          tenantName: name
        }
      }
    }
    aggregateParkingTicket {
      count
    }
  }
`

export const QUERY_PARKING_SUBSCRIPTION_PAYMENT_WITH_USER_FILTER = gql`
  ${PARKING_SUBSCRIPTION_FRAGMENT}
  query queryParkingTicket($first: Int, $offset: Int, $userFilter: UserFilter) {
    queryParkingTicket(first: $first, offset: $offset)
      # order: { desc: created_at }
      @cascade(fields: ["parking_subscription", "user"]) {
      ...ParkingTicketParts
      payment {
        paymentId: session_id
        createdAt: created_at
        status
        sandbox
        amount
      }
      carPlate: car_plate
      carPlate2: car_plate_2
      user(filter: $userFilter) {
        login
        firstName: first_name
        lastName: last_name
        tenant {
          tenantName: name
        }
      }
    }
    aggregateParkingTicket {
      count
    }
  }
`
export const QUERY_PARKING_SUBSCRIPTION_PAYMENT_CSV = gql`
  ${PARKING_SUBSCRIPTION_FRAGMENT}
  query queryParkingTicket {
    queryParkingTicket @cascade(fields: ["parking_subscription"]) {
      ...ParkingTicketParts
      payment {
        paymentId: session_id
        createdAt: created_at
        status
        sandbox
        amount
      }
      carPlate: car_plate
      carPlate2: car_plate_2
      user {
        login
        firstName: first_name
        lastName: last_name
        tenant {
          tenantName: name
        }
      }
    }
  }
`
