import { useMemo, ReactNode, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { IUser } from '../../graphql/users/queryUserWithPagination'
import RemoveSelectedUserRoles from './RemoveSelectedUserRoles'
import styled from 'styled-components'
import { TextBody1Bold } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'
import Table from '../Table'
import { ColumnDef } from '@tanstack/react-table'

export interface UserRoleRow {
  fullName: string
  login: string
  tenantName: string
  disabled: string
  roles: string
  userRoles: string[]
}

const UserRoleTable = ({
  data,
  role,
  children,
  refetch,
}: {
  data: IUser[]
  role: string
  children: ReactNode
  refetch: () => void
}) => {
  const { t } = useTranslation(['columns', 'user_details', 'admin_menu'])
  const [selectedUsers, setSelectedUsers] = useState<UserRoleRow[]>([])

  const handleSelectRows = useCallback((selectedRowsData: UserRoleRow[]) => {
    console.log(selectedRowsData)
    setSelectedUsers(selectedRowsData)
  }, [])

  const csvHeaders = [
    {
      label: t('full_name'),
      key: 'fullName',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: t('company'),
      key: 'tenantName',
    },
    {
      label: t('user_details:lock'),
      key: 'disabled',
    },
    {
      label: t('roles'),
      key: 'roles',
    },
  ]

  const columns: ColumnDef<UserRoleRow, any>[] = useMemo(
    () => [
      {
        header: t('full_name'),
        accessorKey: 'fullName',
      },
      {
        header: t('login'),
        accessorKey: 'login',
      },
      {
        header: t('company'),
        accessorKey: 'tenantName',
      },
      {
        header: t('user_details:lock'),
        accessorKey: 'disabled',
      },
      {
        header: t('roles'),
        accessorKey: 'roles',
      },
    ],
    [t],
  )

  const tableData: UserRoleRow[] = useMemo(() => {
    return (
      data.map(({ login, firstName, lastName, tenant, roles, disabled }) => {
        const activeRoles = roles.map(({ name }) => name)
        return {
          login,
          fullName: `${firstName} ${lastName}`,
          tenantName: tenant?.name || '',
          disabled: disabled ? t('bool:yes') : t('bool:no'),
          roles: activeRoles.join(', '),
          userRoles: activeRoles,
        }
      }) || []
    )
  }, [data, t])

  return (
    <Table
      columns={columns}
      data={tableData}
      enableRowSelection
      csvExport
      csvHeaders={csvHeaders}
      sortByKey="fullName"
      onSelectionChange={handleSelectRows}
      filterCsvValue={t('bool:yes')}
      filterCsvKey={'disabled'}
    >
      {children}
      {!!selectedUsers.length && (
        <Text color={themeColors.primary}>{`${selectedUsers.length} ${t(
          'generic:selected',
        )}`}</Text>
      )}
      <RemoveSelectedUserRoles
        users={selectedUsers}
        role={role}
        refetch={refetch}
      />
    </Table>
  )
}

export default UserRoleTable

const Text = styled(TextBody1Bold)`
  margin-right: 16px;
`
