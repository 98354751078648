import { FC } from 'react'
import { Grid } from '@mui/material'
import { IUser } from '../../graphql/users/queryUserWithPagination'
import styled from 'styled-components'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import { useTranslation } from 'react-i18next'
import { themeColors } from '../../const/colors'
import { UserAnonymizeStatus } from '../../graphql/users/queryUserWithPagination'
import { dateIso2localeString } from '../../utils/formatDate'
import UserLinks from './UserLinks'

const UserInfo: FC<{ data: IUser; refetch: () => void }> = ({
  data,
  refetch,
}) => {
  const { t, i18n } = useTranslation(['user_details', 'form', 'btn'])
  const lang = i18n.language
  const {
    login,
    email,
    workEmail,
    firstName,
    lastName,
    disabled,
    anonymizeStatus,
    anonymizedDate,
  } = data

  const readOnlyData = [
    { label: t('form:login'), value: login },
    { label: t('form:contact_email'), value: email?.email },
    { label: t('form:work_email'), value: workEmail?.email },
    { label: t('form:first_name'), value: firstName },
    { label: t('form:last_name'), value: lastName },
  ]

  return (
    <Wrapper container rowSpacing={2} columnSpacing={2}>
      <Grid item xs={6}>
        <BlueContainer>
          {readOnlyData.map(
            (item) =>
              item.value && (
                <Row key={item.label}>
                  <Text data-e2e="usersList-userBaseInfo-textLabelTitle">
                    {`${item.label}: `}
                  </Text>
                  <TextBold data-e2e="usersList-userBaseInfo-textLabelValue">
                    {`${item.value}`}
                  </TextBold>
                </Row>
              ),
          )}
          {disabled && (
            <TextBold color={themeColors.error}>
              {t('switch_lock_disabled')}
            </TextBold>
          )}
          {((anonymizeStatus &&
            anonymizeStatus !== UserAnonymizeStatus.empty) ||
            anonymizedDate) && (
            <>
              {!!anonymizeStatus && (
                <Row>
                  <Text>{`${t('anonymize_status')} `}</Text>
                  <TextBold>{anonymizeStatus}</TextBold>
                </Row>
              )}
              {!!anonymizedDate && (
                <Row>
                  <Text>{t('anonymized_date')}</Text>
                  <TextBold>
                    {dateIso2localeString(anonymizedDate, lang)}
                  </TextBold>
                </Row>
              )}
            </>
          )}
        </BlueContainer>
      </Grid>
      <Grid item xs={6}>
        <UserLinks login={login} />
      </Grid>
    </Wrapper>
  )
}

export default UserInfo
const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const Row = styled(Grid)`
  padding: 2px 0;
`
const BlueContainer = styled(Grid)`
  background-color: ${themeColors.lightBlue};
  border-radius: 20px;
  padding: 20px;
  margin: 16px 0;
  height: 100%;
`
const Wrapper = styled(Grid)`
  margin-bottom: 16px;
`
