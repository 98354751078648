import { useEffect } from 'react'
import { Grid, Chip, Stack, Button } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { themeColors } from '../../const/colors'
import { InputAdornment } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { BaseTextButton } from '../Shared/BaseButton'
import { useForm } from 'react-hook-form'
import Input from '../Shared/Input'
import { TextBody1Bold } from '../Styles/TextCustom'
import { ParkingTicketPaymentFilter } from '../Parking/ParkingPaymentHistoryTable'
import FilterSelect from '../Shared/FilterSelect'
import DatePicker from '../../components/Shared/DatePickerController'
import BaseSelect from '../Shared/BaseSelect'
import { PaymentStatus } from '../../graphql/shared/sharedTypes'
import { dateIso2localeString } from '../../utils/formatDate'
import { ParkingProductType } from '../../screens/Parking/ParkingPaymentHistory'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../const/routes'

type FormData = {
  value: string
  status: {
    label: string
    value: string
  }
  date: string
}

const ParkingPaymentFilters = ({
  productType,
  setCurrentPage,
  filterKey,
  setFilterKey,
  filterValue,
  setFilterValue,
  params,
}: {
  productType: ParkingProductType
  setCurrentPage: (v: number) => void
  filterKey: ParkingTicketPaymentFilter | ''
  setFilterKey: (v: ParkingTicketPaymentFilter | '') => void
  filterValue: string
  setFilterValue: (v: string) => void
  params?: string
}) => {
  const { t, i18n } = useTranslation(['payment_history'])
  const lang = i18n.language
  const navigate = useNavigate()
  const paymentStatus = filterKey === ParkingTicketPaymentFilter.paymentStatus
  const paymentDate = filterKey === ParkingTicketPaymentFilter.paymentDate
  const filters = [
    ParkingTicketPaymentFilter.paymentId,
    ParkingTicketPaymentFilter.paymentDate,
    ParkingTicketPaymentFilter.paymentStatus,
    ParkingTicketPaymentFilter.login,
    ParkingTicketPaymentFilter.lastName,
    ParkingTicketPaymentFilter.carPlate,
  ]
  const statuses = [
    PaymentStatus.new,
    PaymentStatus.received,
    PaymentStatus.processing,
    PaymentStatus.confirmed,
    PaymentStatus.completed,
    PaymentStatus.failed,
    PaymentStatus.error,
    PaymentStatus.cancelled,
    PaymentStatus.timedout,
    PaymentStatus.partially_refunded,
    PaymentStatus.fully_refunded,
  ]

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>()

  const handleClearFilters = async () => {
    if (!filterKey && !filterValue) {
      return null
    }
    if (params) {
      navigate(`../${ROUTES.PARKING_PAYMENT_HISTORY}`, { replace: true })
      await setFilterKey('')
      await setFilterValue('')
    } else {
      await setFilterKey('')
      await setFilterValue('')
      await reset()
    }
  }

  const onSubmit = async (data: FormData) => {
    const { value, status, date } = data

    if (paymentStatus) {
      await setFilterValue(status.value)
    } else if (paymentDate) {
      await setFilterValue(date)
    } else await setFilterValue(value.trim())
    await setCurrentPage(0)
  }

  const handleFilterKey = async (v: ParkingTicketPaymentFilter) => {
    await setFilterValue('')
    await setFilterKey(v)
  }

  const displayValue = (v: string) =>
    filterKey === ParkingTicketPaymentFilter.paymentDate
      ? dateIso2localeString(v, lang, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : filterKey === ParkingTicketPaymentFilter.paymentStatus
        ? t(`payment_status:${v}`)
        : v

  useEffect(() => {
    handleClearFilters()
  }, [productType])

  return (
    <Wrapper>
      <TextBody1Bold color={themeColors.primary}>
        {t('find_payment')}
      </TextBody1Bold>

      <Row>
        <CenterContainer>
          <FilterSelect
            value={filterKey}
            setValue={handleFilterKey}
            filters={filters.map((item) => ({ name: item }))}
          />
        </CenterContainer>

        {!paymentStatus && !paymentDate && (
          <Container>
            <Input
              label={t('btn:search')}
              name="value"
              control={control}
              errors={errors}
              rules={{ required: `${t('form:field_required')}` }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faSearch as IconProp}
                      size="lg"
                      color={themeColors.secondary}
                    />
                  </InputAdornment>
                ),
              }}
              disabled={!filterKey}
              data-e2e="filter-input"
            />
          </Container>
        )}
        {paymentStatus && (
          <CenterContainer>
            <BaseSelect
              options={statuses.map((item) => ({
                value: item,
                label: t(`payment_status:${item}`),
              }))}
              control={control}
              name="status"
              label="Status"
              placeholder="Wybierz status"
            />
          </CenterContainer>
        )}
        {paymentDate && (
          <DatePickerWrapper>
            <DatePicker
              control={control}
              errors={errors}
              name="date"
              label={t('payment_date_from')}
            />
          </DatePickerWrapper>
        )}

        <SubmitButton onClick={handleSubmit(onSubmit)} disabled={!filterKey}>
          {t('btn:submit_filters')}
        </SubmitButton>
      </Row>
      <Stack direction="row" spacing={1}>
        {filterValue && (
          <ChipStyled
            label={`${t(`user_search:${filterKey}`)}: ${displayValue(filterValue)}`}
          />
        )}
        {filterValue && (
          <ButtonStyled variant="text" onClick={handleClearFilters}>
            {t('btn:clear_filters')}
          </ButtonStyled>
        )}
      </Stack>
    </Wrapper>
  )
}

export default ParkingPaymentFilters

const Wrapper = styled(Grid)`
  background-color: ${themeColors.lightBlue};
  padding: 8px 16px;
  border-radius: 30px;
  margin-bottom: 16px;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`
const ChipStyled = styled(Chip)`
  color: ${themeColors.primary};
  background-color: ${themeColors.lightBlue};
  font-size: 14px;
  font-weight: 500;
`
const ButtonStyled = styled(Button)`
  text-transform: none;
  font-weight: 700;
  margin-bottom: 16px;
`
const Container = styled.div`
  flex: 1;
  padding: 0 16px;
  max-width: 500px;
`
const DatePickerWrapper = styled(Grid)`
  background-color: ${themeColors.white};
  width: 242px;
  margin: 0 16px 16px;
`
const CenterContainer = styled.div`
  padding: 0 0 16px 16px;
  min-width: 242px;
`
const SubmitButton = styled(BaseTextButton)`
  margin-bottom: 16px;
`
